@tailwind base;
@tailwind components;
@tailwind utilities;

.py-05 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.markdown {
  @apply break-words text-xs leading-normal text-gray-900;
}

.markdown > * + * {
  @apply mb-4 mt-0;
}

.markdown li + li {
  @apply mt-1;
}

.markdown li > p + p {
  @apply mt-6;
}

.markdown strong {
  @apply font-semibold;
}

.markdown a {
  @apply font-semibold text-blue-600;
}

.markdown strong a {
  @apply font-bold;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  @apply mb-4 mt-5 border-b pb-2 text-sm font-semibold leading-tight;
}

.markdown blockquote {
  @apply border-l-4 border-gray-300 pl-4 pr-4 text-gray-600;
}

.markdown code {
  @apply py-05 inline rounded bg-gray-200 px-1 font-mono;
}

.markdown pre {
  @apply rounded bg-gray-100 p-4;
}

.markdown pre code {
  @apply block overflow-visible rounded-none bg-transparent p-0;
}

.markdown ul {
  @apply list-disc pl-8;
}

.markdown ol {
  @apply list-decimal pl-8;
}

.markdown table {
  @apply border-gray-600;
}

.markdown th {
  @apply border px-3 py-1;
}

.markdown td {
  @apply border px-3 py-1;
}

/* Override pygments style background color. */
.markdown .highlight pre {
  @apply bg-gray-100 !important;
}
